<template>
    <div class="search-layout">
        <topWelcomeBanner style="position: absolute; top: 60px; width: 100%; z-index: 1;"></topWelcomeBanner>
        <slot></slot>
    </div>
</template>

<script>
import topWelcomeBanner from "@/components/topWelcomeBanner.vue";

export default {
    name: "searchLayout",
    components: {
        topWelcomeBanner
    }
}
</script>

<style lang="scss">
.search-layout {
    height: 100%;
}

</style>